import { render, staticRenderFns } from "./AddMerchantUsers.vue?vue&type=template&id=af45de58&scoped=true"
import script from "./AddMerchantUsers.vue?vue&type=script&lang=js"
export * from "./AddMerchantUsers.vue?vue&type=script&lang=js"
import style0 from "./AddMerchantUsers.vue?vue&type=style&index=0&id=af45de58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af45de58",
  null
  
)

export default component.exports