import * as config from '@/config/config'

export default {
  data() {
    return {
      confirmUnsaveDialogVisible: false,
      nextRoute: null
    }
  },
  methods: {
    async goToNext() {
      this.confirmUnsaveDialogVisible = false
      this.$store.commit('UPDATE_ADD_MERCHANT_FORM_CHANGED', false)
      this.$store.commit('UPDATE_ADD_LOCATION_FORM_CHANGED', false)
      await this.$store.dispatch('setLocationUpdatingStatus', false)
      if (this.nextRoute?.name === 'AllMerchants') {
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
      }
      if (this.nextRoute?.name === 'Merchant') {
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
      }
      await this.$router.push({
        name: this.nextRoute.name,
        params: this.nextRoute.params
      })
      this.$store.commit('RESET_ADD_MERCHANT_DATA')
    },
    beforePageLeave(to, from, next) {
      if (
        !this.$store.state.isAddMerchantFormChanged &&
        !this.$store.state.isAddLocationFormChanged &&
        !this.$store.state.isUpdatingLocation
      ) {
        next(true)
      } else {
        this.nextRoute = to
        this.confirmUnsaveDialogVisible = true
        return false
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    return this.beforePageLeave(to, from, next)
  },
  beforeRouteUpdate(to, from, next) {
    this.beforePageLeave(to, from, next)
  }
}
