<template>
  <div class="add-user-table">
    <v-form ref="form" class="form">
      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="form-label">Parking provider name</div>
          <v-text-field
            v-model="formData.name"
            solo
            flat
            background-color="#F6F6F6"
            dense
            :rules="[rules.required]"
            class="form-font error-border pkmn-input"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="form-label">Business ID</div>
          <v-text-field
            v-model="formData.business_id"
            solo
            flat
            background-color="#F6F6F6"
            dense
            :rules="[rules.required]"
            class="form-font error-border pkmn-input"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="form-label">Contact email</div>
          <v-text-field
            v-model="formData.contact_email"
            solo
            flat
            background-color="#F6F6F6"
            dense
            validate-on-blur
            :rules="[rules.required, rules.email]"
            class="form-font error-border pkmn-input"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="custom-row">
        <div
          class="custom-icon"
          @click="openImagePicker"
          ref="placeholder"
          :style="{
            backgroundImage: formData.logoImage
              ? `url(${formData.logoImage})`
              : `url(${require('@/assets/logo-placeholder.png')})`, // Display the actual logo image if available, otherwise use the placeholder image
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }"
        >
          <div class="icon-line">
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              accept="image/png"
            />
          </div>
        </div>
        <v-col cols="12" sm="7" class="custom-content" @click="openImagePicker">
          <div class="content-title">Upload logo</div>
          <div class="content-description">400x400 png</div>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn v-if="showDeleteButton" icon @click="removeImage">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="fileError">
          <span class="error-color file-error">Logo size exceeds 2 MB</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
/* import FormAddUser from './FormAddUser.vue' */
import rules from '@/helpers/validation'

export default {
  name: 'AddMerchantInfo',
  components: {
    //   FormAddUser
  },
  props: {
    merchant: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: rules,
      fileError: false,
      formData: {
        name: null,
        business_id: null,
        contact_email: null,
        logoImage: null
      },
      tab: null,
      items: [
        { tab: 'Advanced Settings', content: 'TabSetting' },
        { tab: 'Payouts', content: 'TabPayouts' }
      ],
      showDeleteButton: false
    }
  },
  computed: {
    addMerchantFormData() {
      return this.$store.getters.addMerchant
    }
  },
  methods: {
    validate() {
      return (
        this.formData.name !== null && this.formData.business_id !== null,
        this.formData.contact_email !== null
      )
    },
    submit() {
      this.$emit('submit', this.formData)
    },
    openImagePicker() {
      const input = this.$refs.fileInput
      input.click()
      input.addEventListener('change', () => {
        const file = input.files[0]
        const fileSize = file.size / 1024 / 1024 // in MiB
        this.removeImage()
        if (fileSize > 2) {
          this.fileError = true
          return
        }
        this.fileError = false
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.formData.logoImage = reader.result
          const placeholder = this.$refs.placeholder
          placeholder.style.backgroundImage = `url(${this.formData.logoImage})`
          this.showDeleteButton = true
          this.addMerchantFormData.logo = this.formData.logoImage
        })
        reader.readAsDataURL(file)
      })
    },
    handleFileUpload() {
      this.imageUrl = URL.createObjectURL(this.file)
    },
    removeImage() {
      this.formData.logoImage = null // reset logoImage to empty string
      this.showDeleteButton = false // set showDeleteButton to false
      this.addMerchantFormData.logo = null
    }
  },
  watch: {
    '$store.getters.addMerchant': {
      handler(val) {
        this.$store.commit('UPDATE_ADD_MERCHANT_FORM_CHANGED', true)
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.addMerchantFormData.name = newValue.name
        this.addMerchantFormData.business_id = newValue.business_id
        this.addMerchantFormData.contact_email = newValue.contact_email
        const validated =
          (newValue.name !== null && newValue.business_id !== null,
          rules.isValidEmail(newValue.contact_email))
        this.$emit('validated', validated)
      },
      deep: true
    },
    merchant(value) {
      this.formData = {
        name: value.name,
        business_id: value.business_id,
        contact_email: value.contact_email,
        logoImage: value.logo
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
@import '@/style/variables';
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}

.add-merchant-info-wrapper {
  height: 100%;
  width: 100%;
}

.file-error {
  display: block;
  font-size: 14px;
  position: relative;
  top: -10px;
}

.form {
  width: 339px;
  height: 100% !important;

  .form-font .v-input__slot {
    --padding: 10px;
    background: #eaeaea !important;
  }

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .image-placeholder {
    width: 56px;
    height: 56px;
    background: $color-gray-background;
    color: $text-color-light;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }

  .image-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #333333;
  }

  .image-des {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: rgba(31, 31, 31, 0.5);
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}

.custom-row {
  width: 339px;
  height: 64px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  padding-left: 12px;
  .custom-icon {
    padding: 13px 4px;
    border-radius: 40px;
    border: 0.5px solid #e5e5e5;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;

    .icon-line {
      width: 24px;
      height: 6px;
    }
  }

  .custom-content {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    display: inline-flex;
    cursor: pointer;
    .content-title {
      align-self: stretch;
      color: #333333;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 19.6px;
      word-wrap: break-word;
    }

    .content-description {
      align-self: stretch;
      color: rgba(31, 31, 31, 0.5);
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 15.6px;
      word-wrap: break-word;
    }
  }
}
.add-user-table {
  padding-top: 12px !important;
}
</style>
