<template>
  <div>
    <div class="add-user-table">
      <v-row>
        <v-col cols="23">
          <data-table-users
            @edit="editUser"
            @delete="deleteUser"
            :data="users"
            :showEdit="true"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DataTableUsers from '@/components/Merchant/DataTable/DataTableUsers'

export default {
  name: 'AddMerchantUsers',
  props: ['users'],
  components: {
    DataTableUsers
  },
  methods: {
    editUser(user) {
      this.$emit('edit', user)
    },
    deleteUser(user) {
      this.$emit('delete', user)
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
.add-user-table {
  padding-top: 12px !important;
}
</style>
