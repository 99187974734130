<template>
  <home-template>
    <div class="text-start" slot="content">
      <div class="merchat-top-container">
        <v-row class="merchat-top-container-title-label">
          <div class="text-title">{{ $t('link.adding_merchant') }}</div>
        </v-row>

        <v-row class="tabs-with-paddings">
          <v-tabs v-model="tab">
            <v-tab class="pl-0"> {{ $t('info') }} </v-tab>
            <v-tab :disabled="isTapInfoValidate === false">
              {{ $t('tab.advanced_settings') }}
            </v-tab>
            <v-tab :disabled="isTapSettingsValidate === false">
              {{ $t('reports') }}
            </v-tab>
            <v-tab :disabled="isTapReportValidate === false">
              {{ $t('tab.users') }}
            </v-tab>

            <v-col class="button-panel">
              <v-row>
                <v-spacer />
                <v-btn
                  class="pkmn-button add-user"
                  color="primary"
                  v-show="isUsersTab"
                  @click="openAddUserForm()"
                >
                  {{ $t('link.adding_user') }}
                </v-btn>
                <v-btn
                  class="pkmn-button add-report-id mr-2"
                  color="primary"
                  outlined
                  v-show="isReportsTab"
                  @click="openSetReportRecipientsPopup"
                >
                  {{ $t('set_report_recipients') }} ({{
                    recipientsList.length || 0
                  }})
                </v-btn>
                <v-btn
                  class="pkmn-button add-report-id"
                  color="primary"
                  v-show="isReportsTab"
                  @click="openAddReportForm"
                >
                  {{ $t('report_settings') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-tabs>
        </v-row>
      </div>
      <v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-info
              ref="tabInfo"
              :merchant="merchant"
              @validated="tapInfoValidated"
              @submit="openReportsList"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-settings
              ref="tabSettings"
              :merchant="merchant"
              @validated="tapSettingsValidated"
              @next="openAdvancedSettings()"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider
              style="
                margin-left: 0px !important;
                margin-right: 422px !important;
              "
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider
              style="margin-left: 0px !important; margin-right: 148px"
              :class="{
                'remove-margin': !(
                  auth.isParkmanAdmin() || auth.isMerchantAdmin()
                )
              }"
            />
            <tab-users
              :users="users"
              @edit="openEditUserForm"
              @delete="openDeleteUserForm"
              @next="openAdvancedSettings"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-row>

      <v-card :elevation="6" class="footer">
        <v-card-actions class="justify-space-between">
          <span class="progress-label">Progress: {{ percentComplete }}</span>
          <v-btn
            class="pkmn-button next-button"
            color="primary"
            large
            :disabled="!nextButtonEnabled"
            @click="next()"
          >
            {{ nextButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <form-add-payout-id
        v-if="false"
        ref="formAddReport"
        @submit="isAddReportFormOpening = false"
        @close="isAddReportFormOpening = false"
      />
      <set-report-popup
        :merchant="merchant"
        :report-settings="reportSettings"
        :dialogShown="isAddReportFormOpening"
        @close="closeAddReportForm($event)"
      />

      <set-report-recipients-popup
        v-if="isSetReportRecipientsOpening"
        :recipientsList="recipientsList"
        :reportSettings="reportSettings"
        @close="isSetReportRecipientsOpening = false"
        @refetchRecipients="fetchRecipients"
      />

      <form-add-user
        v-if="isAddUserFormOpening"
        @submit="didAddUser"
        @close="isAddUserFormOpening = false"
      />

      <form-confirm-unsave
        v-if="confirmUnsaveDialogVisible"
        flow="merchant"
        @confirm="goToNext"
        @close="confirmUnsaveDialogVisible = false"
      />
    </div>
  </home-template>
</template>

<script>
import HomeTemplate from '@/templates/Locations'
import TabInfo from '@/components/Merchant/Tab/AddMerchant/AddMerchantInfo.vue'
import TabUsers from '@/components/Merchant/Tab/AddMerchant/AddMerchantUsers.vue'
import TabSettings from '@/components/Merchant/Tab/AddMerchant/AddMerchantSettings.vue'
import FormAddPayoutId from '@/components/Merchant/Form/FormAddPayoutId.vue'
import SetReportPopup from '@/components/Merchant/Form/SetReportPopup.vue'
import SetReportRecipientsPopup from '@/components/Merchant/Form/SetReportRecipientsPopup.vue'
import FormAddUser from '@/components/Merchant/Form/FormAddUser.vue'
import FormConfirmUnsave from '@/components/Merchant/Form/FormConfirmUnsave.vue'
import beforePageLeave from '@/components/Merchant/mixins/beforePageLeave'
import merchantHelpers from '@/helpers/merchant'
import auth from '@/auth'
import * as config from '@/config/config'

const TAB = {
  INFO: 0,
  ADVANCED_SETTINGS: 1,
  REPORTS: 2,
  USERS: 3
}

export default {
  name: 'AddMerchant',
  components: {
    TabInfo,
    TabUsers,
    TabSettings,
    FormAddPayoutId,
    FormAddUser,
    FormConfirmUnsave,
    HomeTemplate,
    SetReportPopup,
    SetReportRecipientsPopup
  },
  mixins: [beforePageLeave],
  data() {
    return {
      auth,
      isAddReportFormOpening: false,
      isSetReportRecipientsOpening: false,
      isAddUserFormOpening: false,
      isTapInfoValidate: false,
      isTapReportValidate: false,
      isTapUserValidate: true,
      isTapSettingsValidate: false,
      tab: '',
      percent: 0,
      users: [],
      merchant: null,
      confirmUnsaveReportDialogVisible: false,
      reportSettings: null,
      recipientsList: []
    }
  },
  methods: {
    async didAddUser(user) {
      this.isAddUserFormOpening = false
      if (
        this.users.filter((theUser) => theUser.email === user.email).length > 0
      ) {
        await this.$store.dispatch('setSystemMessage', {
          type: 'error',
          message: 'The user is already added'
        })
      } else {
        const addedUser = await merchantHelpers.addMerchantUser(
          this.merchant.id,
          user
        )
        if (addedUser.id) {
          this.users.push(addedUser)
        }
      }
    },
    tapInfoValidated(valid) {
      this.isTapInfoValidate = valid
    },
    tapReportValidated(valid) {
      this.isTapReportValidate = valid
    },
    tapSettingsValidated(valid) {
      this.isTapSettingsValidate = valid
    },
    openAddReportForm() {
      this.isAddReportFormOpening = true
    },
    closeAddReportForm(report) {
      this.reportSettings = report
      this.tapReportValidated(true)
      this.isAddReportFormOpening = false
    },
    openSetReportRecipientsPopup() {
      this.isSetReportRecipientsOpening = true
    },

    openReportsList() {
      this.tab = TAB.REPORTS
      this.openAddReportForm()
    },

    openAddUserForm() {
      this.isAddUserFormOpening = true
    },

    openUsersList() {
      this.tab = TAB.USERS
      this.isAddUserFormOpening = false
    },

    openAdvancedSettings() {
      this.tab = TAB.ADVANCED_SETTINGS
    },

    openEditUserFormWithData(data, tmpData) {
      this.editingUser = data
      this.tmpEditingUser = tmpData
      this.isEditUserFormOpening = true
    },

    openEditUserForm(user) {
      // TODO: call api to get the user

      this.openEditUserFormWithData(user, {})
    },

    closeEditUserForm() {
      this.clearEditingUser()
      this.isEditUserFormOpening = false
    },

    openEditUserConfirmationForm(tmpUser) {
      this.tmpEditingUser = tmpUser
      this.isEditUserFormOpening = false
      this.$refs.editUserConfirmation.isOpening = true
    },

    async openDeleteUserForm(user) {
      const isUserDeleted = await merchantHelpers.deleteMerchantUser(
        this.merchant.id,
        user.id
      )
      if (isUserDeleted) {
        const newUsers = this.users.filter((item) => item.email !== user.email)
        this.users = newUsers
      }
    },
    allFormsValid() {
      return [
        this.$refs.tabInfo.validate(),
        this.$refs.tabSettings.validate()
      ].every((item) => item === true)
    },

    async next() {
      if (this.isInfoTab && !this.$refs.tabInfo.validate()) {
        return
      }
      if (this.isAdvancedSettingsTab) {
        if (this.allFormsValid()) {
          await this.manageMerchantData()
        } else {
          await this.$store.dispatch(
            'setSystemMessage',
            this.$t('ask_fill_all_fields')
          )
        }
      } else if (this.isUsersTab) {
        this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('added_confirm_message', {
            object: `${this.$t('merchant')} "${this.merchant.name}"`
          })
        })
        await merchantHelpers.fetchAllMerchants()
        this.$store.commit('UPDATE_ADD_MERCHANT_FORM_CHANGED', false)
        await this.$router.push({
          name: 'Merchant',
          params: { merchantId: this.merchant.id }
        })
      }
      this.tab = (this.tab + 1) % 4
    },
    async manageMerchantData() {
      if (!this.merchant) {
        this.merchant = await merchantHelpers.createMerchant(
          this.addMerchantFormData
        )
        history.pushState(
          {},
          null,
          `${this.$route.path}?id=${this.merchant.id}`
        )
      } else {
        this.merchant = await merchantHelpers.updateMerchant({
          ...this.merchant,
          ...this.addMerchantFormData
        })
      }
    },
    async fetchRecipients() {
      if (this.reportSettings?.id) {
        this.recipientsList = await merchantHelpers.getReceivers(
          this.reportSettings.id
        )
      }
    },
    async fetchReportSettings() {
      this.reportSettings = await merchantHelpers.fetchReportSettings(
        this.merchant.id
      )
    },
    async fetchMerchant(merchantId) {
      await this.$store.dispatch('setShowOverlay', true)
      const merchant = await merchantHelpers.fetchMerchant(merchantId)
      if (!merchant) {
        await this.$router.push({ name: 'AllMerchants' })
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
        return
      }
      this.merchant = merchant
      await this.$store.dispatch('setShowOverlay', false)
    },
    async fetchUsers() {
      const response = await merchantHelpers.fetchAllUsersForMerchant(
        this.merchant.id
      )
      if (response) {
        const users = Array.isArray(response.data) ? response.data : []
        this.users = users
      }
    }
  },
  computed: {
    nextButtonEnabled() {
      if (this.isInfoTab) {
        return this.isTapInfoValidate
      }
      if (this.isReportsTab) {
        return this.isTapReportValidate
      }
      if (this.isUsersTab) {
        return this.isTapUserValidate
      }
      if (this.isAdvancedSettingsTab) {
        return this.isTapSettingsValidate
      }
      return false
    },
    addMerchantFormData() {
      return this.$store.getters.addMerchant
    },
    addButtonVisible() {
      return this.isUsersTab || this.isReportsTab
    },
    addingButtonText() {
      if (this.isUsersTab) {
        return 'Add new user'
      } else {
        return 'Add new Payout Id'
      }
    },
    isInfoTab() {
      return this.tab === TAB.INFO
    },
    isReportsTab() {
      return this.tab === TAB.REPORTS
    },
    isUsersTab() {
      return this.tab === TAB.USERS
    },
    isAdvancedSettingsTab() {
      return this.tab === TAB.ADVANCED_SETTINGS
    },
    nextButtonText() {
      if (this.isUsersTab) {
        return 'Save and Continue'
      } else {
        return 'Next'
      }
    },
    percentComplete() {
      return this.percent + '%'
    }
  },
  created() {
    this.$store.dispatch('setMerchantAddingStatus', true)
    if (this.$route.query.id) {
      this.fetchMerchant(this.$route.query.id)
    }
  },
  destroyed() {
    this.$store.dispatch('setMerchantAddingStatus', false)
  },
  watch: {
    async tab() {
      if (this.isAdvancedSettingsTab) {
        this.percent = 25
      } else if (this.isReportsTab) {
        this.percent = 50
        if (this.merchant.id) {
          await this.fetchReportSettings()
          await this.fetchRecipients()
        }
        if (this.reportSettings.length === 0) {
          this.openAddReportForm()
        }
      } else if (this.isUsersTab) {
        if (this.users.length === 0) {
          if (this.merchant.id) {
            await this.fetchUsers()
          }
          this.openAddUserForm()
        }
        this.percent = 75
      } else {
        this.percent = 0
      }
    }
  },
  async mounted() {
    if (this.$store.getters.countries.length === 0) {
      await merchantHelpers.fetchCountries()
    }

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.footer {
  position: absolute;
  padding: 8px 32px;
  bottom: 0;
  right: 0;
  left: $sidebar-width;
}

.next-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 168px;
  height: 46px;
}

.add-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  width: 122px;
  height: 38px;
}

.add-payout-id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
}

.button-panel {
  padding: 0px 68px 40px;
}

.tab-item {
  overflow-y: auto;
  transition: false;
}

.v-window__container {
  height: 100%;
}

.progress-label {
  color: rgba(31, 31, 31, 0.5);

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.remove-margin {
  margin-right: 0 !important;
}
</style>
