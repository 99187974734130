var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-user-table"},[_c('v-form',{ref:"form",staticClass:"form"},[_c('v-row',{staticStyle:{"height":"72px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-label"},[_vm._v("Parking provider name")]),_c('v-text-field',{staticClass:"form-font error-border pkmn-input",attrs:{"solo":"","flat":"","background-color":"#F6F6F6","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1),_c('v-row',{staticStyle:{"height":"72px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-label"},[_vm._v("Business ID")]),_c('v-text-field',{staticClass:"form-font error-border pkmn-input",attrs:{"solo":"","flat":"","background-color":"#F6F6F6","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.business_id),callback:function ($$v) {_vm.$set(_vm.formData, "business_id", $$v)},expression:"formData.business_id"}})],1)],1),_c('v-row',{staticStyle:{"height":"72px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"form-label"},[_vm._v("Contact email")]),_c('v-text-field',{staticClass:"form-font error-border pkmn-input",attrs:{"solo":"","flat":"","background-color":"#F6F6F6","dense":"","validate-on-blur":"","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.formData.contact_email),callback:function ($$v) {_vm.$set(_vm.formData, "contact_email", $$v)},expression:"formData.contact_email"}})],1)],1),_c('v-row',{staticClass:"custom-row"},[_c('div',{ref:"placeholder",staticClass:"custom-icon",style:({
          backgroundImage: _vm.formData.logoImage
            ? `url(${_vm.formData.logoImage})`
            : `url(${require('@/assets/logo-placeholder.png')})`, // Display the actual logo image if available, otherwise use the placeholder image
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain'
        }),on:{"click":_vm.openImagePicker}},[_c('div',{staticClass:"icon-line"},[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/png"}})])]),_c('v-col',{staticClass:"custom-content",attrs:{"cols":"12","sm":"7"},on:{"click":_vm.openImagePicker}},[_c('div',{staticClass:"content-title"},[_vm._v("Upload logo")]),_c('div',{staticClass:"content-description"},[_vm._v("400x400 png")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[(_vm.showDeleteButton)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.removeImage}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),(_vm.fileError)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"error-color file-error"},[_vm._v("Logo size exceeds 2 MB")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }