<template>
  <div class="">
    <v-form ref="form" class="form add-user-table">
      <v-row class="form-row">
        <v-col cols="12">
          <div class="text-input-label">Country</div>
          <v-select
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.country_id"
            :rules="[rules.required]"
            class="pkmn-input error-border"
            item-value="id"
            item-text="name"
            :items="countries"
          />
        </v-col>
      </v-row>

      <v-row class="form-row">
        <v-col cols="12">
          <div class="text-input-label">Provider type</div>
          <v-select
            solo
            flat
            dense
            :rules="[rules.required]"
            background-color="#F6F6F6"
            v-model="formData.merchant_type_id"
            class="pkmn-input error-border"
            :items="providerTypes"
          />
        </v-col>
      </v-row>

      <v-row class="form-row">
        <v-col cols="6">
          <div class="text-input-label">Default VAT</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.vat_percentage"
            class="pkmn-input pkmn-input--inline"
            type="number"
            min="0"
            suffix="%"
            :rules="[rules.onlyPositive]"
            :hint="vatHint"
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">Currency</div>
          <v-select
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.currency_id"
            class="pkmn-input"
            :items="currencyTypes"
            item-value="id"
            item-text="name"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row class="form-row">
        <v-col cols="6">
          <div class="text-input-label">Merchant kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.kick_back_fee_percentage"
            class="pkmn-input pkmn-input--inline"
            suffix="%"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">Merchant kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.kick_back_fee_fixed"
            class="pkmn-input pkmn-input.custom-suffix"
            :suffix="currencyIcon"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="form-row">
        <v-col cols="6">
          <div class="text-input-label">ParkMan kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.commission_percentage"
            class="pkmn-input pkmn-input--inline"
            suffix="%"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">ParkMan kick back in</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.commission_fixed"
            class="pkmn-input"
            :suffix="currencyIcon"
            type="number"
            min="0"
            :rules="[rules.onlyPositive]"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import rules from '@/helpers/validation'
import { CurrencyTypesEnum } from '@/enums'

export default {
  name: 'AddMerchantSettings',
  props: {
    merchant: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: rules,
      formData: {
        country_id: null,
        merchant_type_id: null,
        currency_id: null,
        vat_percentage: null,
        kick_back_fee_percentage: 0,
        kick_back_fee_fixed: 0,
        commission_percentage: 0,
        commission_fixed: 0
      },
      providerTypes: [
        {
          text: 'Municipality',
          value: 1
        },
        {
          text: 'Company',
          value: 2
        },
        {
          text: 'Individual',
          value: 3
        },
        {
          text: 'Restricted',
          value: 4
        }
      ],
      currencies: ['USD', 'EUR', 'GBP', 'DKK'],
      currencyTypes: CurrencyTypesEnum
    }
  },
  computed: {
    countries() {
      return this.$store.getters.countries
    },
    addMerchantFormData() {
      return this.$store.getters.addMerchant
    },
    currencyIcon() {
      if (this.formData.currency_id) {
        return this.currencyTypes[this.formData.currency_id - 1].symbol
      }
      return null
    },
    vatHint() {
      if (this.formData.vat_percentage === null || this.formData.vat_percentage === '') {
        return 'Country VAT by default'
      }
      return ''
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  watch: {
    'formData.currency'(newValue) {
      this.currencySuffix = newValue
    },
    formData: {
      handler(newValue) {
        this.addMerchantFormData.country_id = newValue.country_id
        this.addMerchantFormData.merchant_type_id = newValue.merchant_type_id
        this.addMerchantFormData.currency_id = newValue.currency_id
        this.addMerchantFormData.vat_percentage = newValue.vat_percentage === null ||  newValue.vat_percentage === "" ? null : newValue.vat_percentage
        this.addMerchantFormData.kick_back_fee_fixed =
          newValue.kick_back_fee_fixed
        this.addMerchantFormData.kick_back_fee_percentage =
          newValue.kick_back_fee_percentage
        this.addMerchantFormData.commission_fixed = newValue.commission_fixed
        this.addMerchantFormData.commission_percentage =
          newValue.commission_percentage

        const validCountry =
          newValue.country_id !== null && newValue.merchant_type_id !== null
        const validCommission =
          newValue.commission_fixed !== null &&
          newValue.commission_percentage !== null
        const validKickBack =
          newValue.kick_back_fee_fixed !== null &&
          newValue.kick_back_fee_percentage !== null
        const validCurrency =
          newValue.currency_id !== null
        this.$emit(
          'validated',
          validCountry && validCommission && validKickBack && validCurrency
        )
        this.$store.commit('UPDATE_ADD_MERCHANT_FORM_CHANGED', true)
      },
      deep: true
    },
    merchant: {
      handler(value) {
        if (value) {
          this.formData = {
            country_id: value.country_id,
            merchant_type_id: value.merchant_type_id,
            currency_id: value.currency_id,
            vat_percentage: value.vat_percentage,
            kick_back_fee_percentage: value.kick_back_fee_percentage,
            kick_back_fee_fixed: value.kick_back_fee_fixed,
            commission_percentage: value.commission_percentage,
            commission_fixed: value.commission_fixed
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.form-row {
  min-height: 60px;
  max-height: 70px;
}
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}

.form {
  width: 339px;
  height: 100% !important;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .v-text-field__suffix {
    color: #fff;
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
.add-user-table {
  padding-top: 12px !important;
}
</style>
